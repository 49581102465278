// src/App.js
import React, { useEffect } from 'react';
import LoadingScreen from './component/LoadingScreen/LoadingScreen';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

const App = () => {
  const { isLoading, error, data } = useVisitorData({ extendedResult: true }, { immediate: true });

  useEffect(() => {
    if (data?.requestId) {
      console.log('Request ID:', data.requestId);

      // Send the requestId to your backend
      fetch('/api/verify-fingerprint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ requestId: data.requestId })
      })
      .then(response => response.json())
      .then(result => {
        console.log('Verification result:', result);
        // Check the response from the backend
        if (result.message === 'Verification Successful') {
          // Reload the page to continue the normal flow
          window.location.href = '/';
        } else {
          // Optionally, display an error message or handle the access denial
          console.error('Verification failed:', result.message);
          // For example, you can redirect to an error page or display a message
        }
      })
      .catch(error => {
        console.error('Error verifying fingerprint:', error);
        // Handle the error appropriately
      });
    }
  }, [data]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <LoadingScreen />
    </div>
  );
};

export default App;
